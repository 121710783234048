@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Aeonik;
    font-weight: 700;
    src: url("./Assets/Font/Aeonik/Aeonik-Bold.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 600;
    src: url("./Assets/Font/Aeonik/Aeonik-Black.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 500;
    src: url("./Assets/Font/Aeonik/Aeonik-Medium.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 400;
    src: url("./Assets/Font/Aeonik/Aeonik-Regular.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 300;
    src: url("./Assets/Font/Aeonik/Aeonik-Air.otf") format("OpenType");
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.phone-input-v1 .PhoneInputInput {
  height: 58px;
}

body {
  /* font-family: "TTI" */
  font-family: "Aeonik", sans-serif;
}

.carousel-container {
  margin: 50px 0 40px;
}
.carousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* height: 60vh; */
}

.carousel-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}
.carousel-header .underline {
  height: 10px;
  border-radius: 6px;
  width: 170px;
  margin-bottom: 24px;
  background: var(--color-secondary);
}
.carousel-header > h3 {
  font-size: 1.8rem;
  margin-bottom: 2px;
  font-family: "Edu NSW ACT Foundation", cursive;
}

.carousel .control-dots {
  text-align: left !important;
  padding-left: 9% !important;
  padding-right: 30px !important;
}
.carousel .control-dots .dot {
  box-shadow: none !important;
  background-color: #fff !important;
  /* background-color: #E3E1E1 !important; */
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  opacity: 1 !important;
  /* background-color: #159AA8 !important; */
  background-color: #fff !important;
}

::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
.hide-arrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-arrows input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove input border and outline */
.phone-input-container1 input {
  border: none !important;
  outline: none !important;
  background-color: #fff;
}
/* Remove code border */
.phone-input-container1 .react-phone-number-input__country-select {
  border: none !important;
  outline: none !important;
  background-color: #fff;
}
/* Custom styles for range input */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.5px;
  background: #6935d3;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #6935d3;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -6.75px; /* Adjust to center the thumb */
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1.5px;
  
  background: #6935d3;
  border-radius: 10px;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #6935d3;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 1.5px;
  background: #6935d3;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #6935d3;
  border-radius: 50%;
  cursor: pointer;
}
