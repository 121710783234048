@keyframes slideInRightWithBounce {
    0% {
      transform: translateX(100%);
    }
    60% {
      transform: translateX(-20px);
    }
    80% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .toast-slide-in {
    animation: slideInRightWithBounce 0.3s ease-in-out forwards;
  }
  
  .toast-slide-out {
    animation: slideOutRight 0.5s ease-in-out forwards;
  }
  